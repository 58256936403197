


























import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import store from "@/store";

@Component({})
export default class accountDetail extends Vue {
  // 字典
  config = store.state.system.config;
  // 表格数据
  tableData = [];
  budanTableData = [];
  // 加载状态
  loading = false;
  loading2 = false;
  btnloading = false;
  // 总条数
  total = 0;
  budanTotal = 0;
  // 弹窗
  tipsDialog = false;
  // 搜索条件
  search: any = {
    keyword: "",
    channelId: "",
    bindingTime: [],
    tradingCenterCode: '',
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    console.log("this.search", this.search);
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post("J30123", this.search)
      .then((data) => {
        this.total = data.total;
        this.tableData = data.list;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  //上传
  fileList: any = [];
  formData = "";
  delFile() {
    this.fileList = [];
  }

  getBase64(file: any) {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      let imgResult: any = "";
      reader.readAsDataURL(file);
      reader.onload = function () {
        imgResult = reader.result;
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.onloadend = function () {
        resolve(imgResult);
      };
    });
  }


  //获取交易中心
  channelList: any = {};
  channelDict() {
    api.post("J30122", { channelId: this.search.channelId }).then((res) => {
      this.channelList = res;
    });
  }

  // 获取出函机构
  outorg: any = {};
  outorgDict() {
    api.post("A13006").then((res) => {
      this.outorg = res;
    });
  }


  handleSelectionChange(row: any) {
    console.log(row)
  }


  activated() {
    let { keyword, channelId } = this.$route.query;
    this.search.keyword = keyword;
    this.search.channelId = channelId;
    this.searchTable();
    this.channelDict();
    this.outorgDict();
  }
}
